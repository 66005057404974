<template>
  <mdb-container class="container">
    <mdb-row center>
      <mdb-col sm="5">
        <mdb-card>
          <mdb-card-body>
            <!-- Material form login -->
            <form>
              <p class="h4 text-center mb-4">로그인</p>
              <div class="grey-text">
                <mdb-input label="전화번호를 입력하세요." icon="phone" type="text" v-model="phone_number" placeholder="'-' 없이 입력"/>
                <mdb-input label="이메일을 입력하세요." icon="envelope" type="email" v-model="email_id"/>
              </div>
              <div class="text-center">
                <mdb-btn @click="login">입장</mdb-btn>
              </div>
            </form>
            <!-- Material form login -->
          </mdb-card-body>
          <router-link to="/user/terms" class="text-right mr-4">
            <p>회원가입</p>
          </router-link>
        </mdb-card>
      </mdb-col>
    </mdb-row>
  </mdb-container>
</template>
<script>
import {mdbContainer, mdbRow, mdbCol, mdbInput, mdbBtn, mdbCardBody, mdbCard} from 'mdbvue';
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: 'Login',
  data() {
    return {
      email_id: '',
      phone_number: '',
    }
  },
  components: {
    mdbInput,
    mdbBtn,
    mdbCardBody,
    mdbCard, mdbContainer, mdbRow, mdbCol,
  },
  methods: {
    login() {
      const self = this;
      const id = self.email_id;
      const password = self.phone_number;

      firebase.auth().signInWithEmailAndPassword(id, password).then(() => {
        self.$router.replace('/');
      }).catch((err) => {
        // 실패 후 실행할 코드
        self.firebaseError(err);
      })
    },
    /**
     * firebase 에러 처리
     * @param error
     */
    firebaseError(error) {
      if ('auth/invalid-verification-code' === error.code) {
        alert('인증번호가 유효하지 않습니다.');
      } else if ('auth/session-expired' === error.code) {
        alert('인증번호가 만료되었습니다.');
      } else if ('auth/too-many-requests' === error.code) {
        alert('잠시 후 다시 시도해 주세요.');
      } else if ('auth/invalid-email' === error.code) {
        alert('이메일을 확인하세요.');
      } else if ('auth/wrong-password' === error.code) {
        alert('전화번호를 확인하세요.');
      } else if ('auth/user-not-found' === error.code) {
        alert('가입된 계정이 아닙니다..');
      } else {
        console.log(error);
      }
    },
  }
}
</script>

<style scoped>
.container {
  margin-top: 150px;
}
</style>
